<template>
  <p class="text-h6 q-ma-md">{{ label }}</p>
  <div
    v-for="(hex, key) in hexObj"
    :key="key"
    style="max-height: 10px"
    class="q-pa-md q-mb-xl"
  >
    <q-input
      filled
      v-model="hexObj[key]"
      :rules="['anyColor']"
      :label="key"
      @change="updateBranding()"
    >
      <template v-slot:prepend>
        <div
          :style="
            'width: 20px; height: 20px; background-color:' +
            hex +
            '; border: solid 1px black'
          "
        ></div>
      </template>
      <template v-slot:append>
        <q-icon name="palette" class="cursor-pointer">
          <q-popup-proxy cover :breakpoint="600">
            <q-color
              v-model="hexObj[key]"
              no-header
              format-model="hexa"
              @change="updateBranding()"
            />
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
  </div>
</template>

<script setup>
import { computed, defineEmits, ref } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: String,
  value: String,
});

const emits = defineEmits(["updateRowRef"]);

const hexObj = ref({});

const branding = computed({
  get() {
    return JSON.parse(props.value);
  },
  set(val) {
    emits("updateRowRef", JSON.stringify(val));
  },
});

for (const [key, value] of Object.entries(branding.value)) {
  hexObj.value[key] = value;
}

function updateBranding() {
  branding.value = hexObj.value;
}
</script>
